import React from 'react';
import { useSelector } from 'react-redux';

import AiChatBot from '../DataPane/AiChatBot';
import { getCurrentMetadata } from '../../../selectors/metadata';

const AiPane = ({ currentSelection }) => {
  const metadata = useSelector((state) => getCurrentMetadata(state.metadataCollection, state.vifAuthoring));

  return currentSelection === 'ai-authoring-data' && <AiChatBot metadata={metadata} />;
};

export default AiPane;
