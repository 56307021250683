import _ from 'lodash';

import { findPaletteCanonicalReference } from 'common/visualizations/helpers/SiteAppearanceColors';

import vifs from '../../vifs';
import baseVifReducer from './base';
import {
  appendSeries,
  forEachSeries,
  setBooleanValueOrDefaultValue
} from '../../helpers';

import * as actions from '../../actions';

export default function pieChart(state, action) {
  if (_.isUndefined(state)) {
    return vifs().pieChart;
  }

  state = _.cloneDeep(state);

  switch (action.type) {
    case actions.RESET_STATE:
      state = vifs().pieChart;
      break;

    case actions.REPLACE_VIF:
      const chartType = _.get(action, 'vif.series[0].type');
      state = chartType === 'pieChart' ? { ...state, ...action.vif } : state;
      break;

    case actions.SET_COLOR_PALETTE:
      _.set(state, 'series[0].color.palette', findPaletteCanonicalReference(action.colorPalette));
      break;

    case actions.SET_CUSTOM_COLOR_PALETTE:
      const customColorPalette = action.customColorPalette;
      const grouping = action.dimensionGroupingColumnName;
      _.set(state, 'series[0].color.customPalette', {
        [grouping]: customColorPalette
      });
      break;

    case actions.UPDATE_CUSTOM_COLOR_PALETTE: {
      const { dimensionGroupingColumnName, group, selectedColor } = action;
      const path = ['series', 0, 'color', 'customPalette', dimensionGroupingColumnName, group, 'color'];
      _.set(state, path, selectedColor);
      break;
    }

    case actions.SET_LIMIT_COUNT_AND_SHOW_OTHER_CATEGORY:
      setBooleanValueOrDefaultValue(state, 'configuration.showOtherCategory', action.showOtherCategory, true);

      forEachSeries(state, series => {
        _.set(series, 'dataSource.limit', action.limitCount);
      });
      break;

    case actions.SET_SHOW_LEGEND:
      _.set(state, 'series[0].showLegend', action.showLegend);
      break;

    case actions.SET_SHOW_OTHER_CATEGORY:
      setBooleanValueOrDefaultValue(state, 'configuration.showOtherCategory', action.showOtherCategory, true);
      break;

    case actions.SET_SHOW_SLICE_PERCENTS_IN_FLYOUTS:
      _.set(state, 'configuration.showSlicePercentsInFlyouts', action.showSlicePercentsInFlyouts);
      break;

    case actions.APPEND_SERIES:
      if (action.isFlyoutSeries) {
        appendSeries(state, action);
      }
      break;

    case actions.REMOVE_SERIES:
      if (action.isFlyoutSeries) {
        return baseVifReducer(state, action);
      }
      break;

    case actions.SET_MEASURE_AGGREGATION:
    case actions.SET_MEASURE_COLUMN:
      if (action.isFlyoutSeries || (action.relativeIndex == 0)) {
        return baseVifReducer(state, action);
      }
      break;

    case actions.ADD_DRILL_DOWN_COLUMN:
    case actions.CHANGE_DRILL_DOWN_COLUMN:
    case actions.RECEIVE_METADATA:
    case actions.REMOVE_DRILL_DOWN_COLUMN:
    case actions.SET_CURRENT_DRILL_DOWN_COLUMN_NAME:
    case actions.SET_DATASET_UID:
    case actions.SET_DESCRIPTION:
    case actions.SET_DIMENSION:
    case actions.SET_DOMAIN:
    case actions.SET_DRILL_DOWNS:
    case actions.SET_FILTERS:
    case actions.SET_LEGEND_POSITION:
    case actions.SET_ORDER_BY:
    case actions.SET_SHOW_VALUE_LABELS:
    case actions.SET_SHOW_VALUE_LABELS_AS_PERCENT:
    case actions.SET_SHOW_NULLS_AS_FALSE:
    case actions.SET_TITLE:
    case actions.SET_SHOW_DATA_TABLE_CONTROL:
    case actions.SET_UNIT_ONE:
    case actions.SET_UNIT_OTHER:
    case actions.SET_VIEW_SOURCE_DATA_LINK:
    case actions.SWAP_COLOR_PALETTE:
      return baseVifReducer(state, action);

    default:
      break;
  }

  return state;
}
