// TODO: Currently, we are using any for all TypeScript properties, but we will update them later.
// See EN-70104 for more details.
import _ from 'lodash';
import vifs from 'common/authoring_workflow/vifs';
import { SoqlFilter } from 'common/components/FilterBar/SoqlFilter';

// only exported for testing coverage
export const getChartType = (type: string): string => {
  const chartTypes: string[] = ['comboChart.line', 'comboChart.column'];

  if (chartTypes.includes(type)) {
    if (type === 'comboChart.line') {
      return 'timelineChart';
    } else if (type === 'comboChart.column') {
      return 'comboChart';
    }
  }

  return type;
};

interface ChartVif {
  chart_type: string;
  datasetUid: string;
  dimensionColumnName: string;
  measureColumnName?: string | null;
  aggregationFunction?: string;
  orderByType?: string;
  orderByOrder?: string;
  soqlFilter?: SoqlFilter;
  title?: string;
}

export const generateVif = (vif: ChartVif) => {
  const chartType = getChartType(vif.chart_type);
  const updateVif = _.cloneDeep(vifs()[chartType]);

  _.set(updateVif, 'series[0].dataSource.datasetUid', vif.datasetUid);
  _.set(updateVif, 'series[0].dataSource.dimension.columnName', vif.dimensionColumnName);
  _.set(updateVif, 'series[0].dataSource.measure.columnName', vif.measureColumnName || null);
  _.set(updateVif, 'series[0].dataSource.measure.aggregationFunction', vif.aggregationFunction);

  if (vif.orderByType) {
    _.set(updateVif, 'series[0].dataSource.orderBy.parameter', vif.orderByType);
    _.set(updateVif, 'series[0].dataSource.orderBy.sort', vif.orderByOrder || '');
  }

  if (vif.soqlFilter) {
    _.set(updateVif, 'series[0].dataSource.filters', _.isEmpty(vif.soqlFilter) ? [] : [vif.soqlFilter]);
  }

  if (chartType === 'map') {
    _.set(updateVif, 'series[0].showLegend', true);
    _.set(updateVif, 'series[0].mapOptions', { mapType: 'pointMap' });
  }

  _.set(updateVif, 'series[0].type', chartType);
  _.set(updateVif, 'title', vif.title || '');

  return updateVif;
};
