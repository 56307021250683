import _ from 'lodash';

import { findPaletteCanonicalReference } from 'common/visualizations/helpers/SiteAppearanceColors';
import * as actions from '../../actions';
import baseVifReducer from './base';
import {
  COLOR_PALETTES,
  DEFAULT_COLOR_PALETTE,
  POINT_AGGREGATIONS,
  QUANTIFICATION_METHODS,
  VIF_CONSTANTS
} from '../../constants';
import { appendSeries, setMeasureColumnAtSeriesIndex } from '../../helpers';
import vifs from '../../vifs';

export default function map(state, action) {
  if (_.isUndefined(state)) {
    return vifs().map;
  }

  function getSeriesIndex() {
    return _.get(state, 'currentMapLayerIndex', VIF_CONSTANTS.CURRENT_MAP_LAYER_INDEX);
  }

  state = _.cloneDeep(state);

  switch (action.type) {
    case actions.RESET_STATE:
      state = vifs().map;
      break;

    case actions.REPLACE_VIF:
      const chartType = _.get(action, 'vif.series[0].type');
      state = chartType === 'map' ? { ...state, ...action.vif } : state;
      break;

    case actions.SET_CURRENT_MAP_LAYER_INDEX:
      _.set(state, 'currentMapLayerIndex', action.currentMapLayerIndex);
      break;

    case actions.SET_DOMAIN:
      _.set(state, `series[${getSeriesIndex()}].dataSource.domain`, action.domain);
      break;

    case actions.SET_DATASET_UID:
      _.set(state, `series[${getSeriesIndex()}].dataSource.datasetUid`, action.datasetUid);
      break;

    case actions.SET_MAP_LAYER_NAME:
      _.set(state, `series[${getSeriesIndex()}].dataSource.name`, action.name);
      break;

    case actions.SET_MAP_LAYER_SOURCE:
      _.set(state, `series[${getSeriesIndex()}].dataSource.source`, action.source);
      break;

    case actions.SET_MID_POINT:
      _.set(state, `series[${getSeriesIndex()}].mapOptions.midpoint`, action.midpoint);
      break;

    case actions.SET_DIMENSION:
      _.unset(state, 'configuration.mapCenterAndZoom');
      _.set(state, `series[${getSeriesIndex()}].dataSource.dimension.columnName`, action.dimension);
      break;

    case actions.SET_PRIMARY_COLOR:
      _.set(state, `series[${getSeriesIndex()}].color.primary`, action.primaryColor);
      break;

    case actions.SET_COLOR_PALETTE:
      _.set(state, `series[${getSeriesIndex()}].color.palette`, findPaletteCanonicalReference(action.colorPalette));
      break;

    case actions.SET_CUSTOM_COLOR_PALETTE:
      const customColorPalette = action.customColorPalette;
      const grouping = action.dimensionGroupingColumnName;

      _.set(state, `series[${action.seriesIndex}].color.customPalette.${grouping}`, customColorPalette);
      break;

    case actions.SET_CUSTOM_COLOR_PALETTE_WITH_QUANTIFICATION: {
      const {
        customColorPalette,
        dimensionGroupingColumnName,
        seriesIndex,
        quantificationMethod
      } = action;

      _.set(state, `series[${seriesIndex}].color.customPalette.${dimensionGroupingColumnName}.${quantificationMethod}`, customColorPalette);
      break;
    }

    case actions.UPDATE_CUSTOM_COLOR_PALETTE: {
      const { dimensionGroupingColumnName, group, selectedColor } = action;
      const seriesIndex = _.isNil(action.seriesIndex) ? getSeriesIndex() : action.seriesIndex;
      const path = ['series', seriesIndex, 'color', 'customPalette', dimensionGroupingColumnName, group, 'color'];

      _.set(state, path, selectedColor);
      break;
    }

    case actions.UPDATE_CUSTOM_COLOR_PALETTE_WITH_QUANTIFICATION: {
      const {
        dimensionGroupingColumnName,
        group,
        selectedColor,
        quantificationMethod,
      } = action;
      const seriesIndex = _.isNil(action.seriesIndex) ? getSeriesIndex() : action.seriesIndex;
      const path = ['series', seriesIndex, 'color', 'customPalette', dimensionGroupingColumnName, quantificationMethod, group, 'color'];

      _.set(state, path, selectedColor);
      break;
    }

    case actions.UPDATE_CUSTOM_CHARM_NAME: {
      const { dimensionGroupingColumnName, group, selectedCharmName } = action;
      const currentSeriesIndex = _.isNil(action.seriesIndex) ? getSeriesIndex() : action.seriesIndex;
      const path = ['series', currentSeriesIndex, 'color', 'customPalette', dimensionGroupingColumnName, group, 'charmName'];

      _.set(state, path, selectedCharmName);
      break;
    }

    case actions.UPDATE_CUSTOM_CHARM_NAME_WITH_QUANTIFICATION: {
      const {
        dimensionGroupingColumnName,
        group,
        selectedCharmName,
        quantificationMethod
      } = action;
      const currentSeriesIndex = _.isNil(action.seriesIndex) ? getSeriesIndex() : action.seriesIndex;
      const path = ['series', currentSeriesIndex, 'color', 'customPalette', dimensionGroupingColumnName, quantificationMethod, group, 'charmName'];

      _.set(state, path, selectedCharmName);
      break;
    }

    case actions.SET_POINT_OPACITY:
      const opacity = parseFloat(action.pointOpacity);

      _.set(state, `series[${getSeriesIndex()}].mapOptions.pointOpacity`, _.isFinite(opacity) ? opacity : null);
      break;

    case actions.SET_POINT_SIZE:
      const pointSize = parseFloat(action.pointSize);

      _.set(
        state,
        `series[${getSeriesIndex()}].mapOptions.pointSize`,
        setDefault({ value: pointSize, constantKey: 'POINT_MAP_POINT_SIZE' })
      );
      break;

    case actions.SET_LINE_COLOR_OPACITY:
      _.set(state, `series[${getSeriesIndex()}].mapOptions.lineColorOpacity`, action.lineColorOpacity);
      break;

    case actions.SET_LINE_WEIGHT:
      const lineWeight = parseFloat(action.lineWeight);

      _.set(
        state,
        `series[${getSeriesIndex()}].mapOptions.lineWeight`,
        setDefault({ value: lineWeight, constantKey: 'LINE_WEIGHT' })
      );
      break;

    case actions.SET_MINIMUM_LINE_WEIGHT:
      const minimumLineWeight = parseFloat(action.minimumLineWeight);

      _.set(state,
        `series[${getSeriesIndex()}].mapOptions.minimumLineWeight`,
        setDefault({ value: minimumLineWeight, constantKey: 'LINE_MAP_MIN_LINE_WEIGHT' })
      );
      break;

    case actions.SET_MAXIMUM_LINE_WEIGHT:
      const maximumLineWeight = parseFloat(action.maximumLineWeight);

      _.set(state,
        `series[${getSeriesIndex()}].mapOptions.maximumLineWeight`,
        setDefault({ value: maximumLineWeight, constantKey: 'LINE_MAP_MAX_LINE_WEIGHT' })
      );
      break;

    case actions.SET_MINIMUM_POINT_SIZE:
      const minimumPointSize = parseFloat(action.minimumPointSize);

      _.set(state,
        `series[${getSeriesIndex()}].mapOptions.minimumPointSize`,
        setDefault({ value: minimumPointSize, constantKey: 'POINT_MAP_MIN_POINT_SIZE' })
      );
      break;

    case actions.SET_MAXIMUM_POINT_SIZE:
      const maximumPointSize = parseFloat(action.maximumPointSize);

      _.set(state,
        `series[${getSeriesIndex()}].mapOptions.maximumPointSize`,
        setDefault({ value: maximumPointSize, constantKey: 'POINT_MAP_MAX_POINT_SIZE' })
      );
      break;

    case actions.SET_NUMBER_OF_DATA_CLASSES:
      const numberOfDataClasses = parseInt(action.numberOfDataClasses);

      _.set(
        state,
        `series[${getSeriesIndex()}].mapOptions.numberOfDataClasses`,
        setDefault({ value: numberOfDataClasses, constantKey: 'NUMBER_OF_DATA_CLASSES' })
      );
      break;

    case actions.SET_COLOR_BY_BUCKETS_COUNT:
      const colorByBucketsCount = parseInt(action.colorByBucketsCount);

      _.set(
        state,
        `series[${getSeriesIndex()}].mapOptions.colorByBucketsCount`,
        setDefault({ value: colorByBucketsCount, constantKey: 'COLOR_BY_BUCKETS_COUNT' })
      );
      break;

    case actions.SET_MAX_CLUSTERING_ZOOM_LEVEL:
      const maxClusteringZoomLevel = parseInt(action.maxClusteringZoomLevel);

      _.set(state,
        'configuration.basemapOptions.maxClusteringZoomLevel',
        setDefault({ value: maxClusteringZoomLevel, constantKey: 'CLUSTERING_ZOOM' })
      );
      break;

    case actions.SET_SHOW_MULTIPLE_POINTS_SYMBOL_IN_LEGEND:
      _.set(state, 'configuration.showMultiplePointsSymbolInLegend', action.showMultiplePointsSymbolInLegend);
      break;

    case actions.SET_POINT_THRESHOLD:
      const pointThreshold = parseInt(action.pointThreshold);

      _.set(state,
        'configuration.basemapOptions.pointThreshold',
        setDefault({ value: pointThreshold, constantKey: 'POINT_THRESHOLD' })
      );
      break;

    case actions.SET_CLUSTER_RADIUS:
      const clusterRadius = parseInt(action.clusterRadius);

      _.set(state,
        'configuration.basemapOptions.clusterRadius',
        setDefault({ value: clusterRadius, constantKey: 'CLUSTER_RADIUS' })
      );
      break;

    case actions.SET_MAX_CLUSTER_SIZE:
      const maxClusterSize = parseInt(action.maxClusterSize);

      _.set(state,
        'configuration.basemapOptions.maxClusterSize',
        setDefault({ value: maxClusterSize, constantKey: 'CLUSTER_SIZE' })
      );
      break;

    case actions.SET_STACK_RADIUS:
      const stackRadius = parseInt(action.stackRadius);

      _.set(state,
        'configuration.basemapOptions.stackRadius',
        setDefault({ value: stackRadius, constantKey: 'STACK_RADIUS' })
      );
      break;

    case actions.SET_RESIZE_POINTS_BY_COLUMN:
      _.set(state, `series[${getSeriesIndex()}].mapOptions.resizePointsBy`, action.resizePointsBy);
      break;

    case actions.SET_COLOR_POINTS_BY_COLUMN:
      _.set(state, `series[${getSeriesIndex()}].mapOptions.colorPointsBy`, action.colorPointsBy);
      break;

    case actions.SET_WEIGH_LINES_BY_COLUMN:
      _.set(state, `series[${getSeriesIndex()}].mapOptions.weighLinesBy`, action.weighLinesBy);
      break;

    case actions.SET_COLOR_LINES_BY_COLUMN:
      _.set(state, `series[${getSeriesIndex()}].mapOptions.colorLinesBy`, action.colorLinesBy);
      break;

    case actions.SET_CENTER_AND_ZOOM:
      _.set(state, 'configuration.mapCenterAndZoom', action.centerAndZoom);
      break;

    case actions.SET_MAP_TYPE:
      _.set(state, `series[${getSeriesIndex()}].mapOptions.mapType`, action.mapType);
      break;

    case actions.SET_BOUNDARY_COLOR_BY_COLUMN:
      _.set(state, `series[${getSeriesIndex()}].mapOptions.colorBoundariesBy`, action.colorBoundariesBy);
      break;

    case actions.SET_COLOR_BY_QUANTIFICATION_METHOD:
      _.set(state, `series[${getSeriesIndex()}].mapOptions.colorByQuantificationMethod`, action.colorByQuantificationMethod);


      const colorPalette = _.get(state, `series[${getSeriesIndex()}].color.palette`, DEFAULT_COLOR_PALETTE);
      const isCategoricalQuantificationMethod = action.colorByQuantificationMethod === QUANTIFICATION_METHODS.category.value;
      const isCustomPalette = colorPalette === 'custom';
      const isValidPalette = _.includes(_.map(COLOR_PALETTES, 'value'), colorPalette);

      if (!isValidPalette && !isCustomPalette && isCategoricalQuantificationMethod) {
        _.set(state, `series[${getSeriesIndex()}].color.palette`, DEFAULT_COLOR_PALETTE);
      }
      break;

    case actions.SET_RANGE_BUCKET_TYPE:
      _.set(state, `series[${getSeriesIndex()}].mapOptions.rangeBucketType`, action.rangeBucketType);
      break;

    case actions.SET_POINT_AGGREGATION:
      _.set(state, `series[${getSeriesIndex()}].mapOptions.pointAggregation`, action.pointAggregation);


      /**
       * If Point Aggregation is set to Region Map, the quantification method should be
       * linear (either equalInterval or Jenk). Note: the UI shows equalInterval as
       * linear in the dropdown.
       */
      if (action.pointAggregation === POINT_AGGREGATIONS.REGION_MAP) {
        _.set(state, `series[${getSeriesIndex()}].mapOptions.colorByQuantificationMethod`, QUANTIFICATION_METHODS.linear.value);
      }

      break;

    case actions.SET_COMPUTED_COLUMN:
      _.set(state, `series[${getSeriesIndex()}].computedColumnName`, action.computedColumn);
      break;

    case actions.SET_SHAPEFILE:
      _.set(state, `series[${getSeriesIndex()}].shapefile.uid`, action.shapefileUid);
      _.set(state, `series[${getSeriesIndex()}].shapefile.primaryKey`, action.shapefilePrimaryKey);
      _.set(state, `series[${getSeriesIndex()}].shapefile.geometryLabel`, action.shapefileGeometryLabel);
      break;

    case actions.SET_SHAPEFILE_UID:
      _.set(state, `series[${getSeriesIndex()}].shapefile.uid`, action.shapefileUid);
      break;

    case actions.SET_SHAPEFILE_PRIMARY_KEY:
      _.set(state, `series[${getSeriesIndex()}].shapefile.primaryKey`, action.shapefilePrimaryKey);
      break;

    case actions.SET_SHAPEFILE_GEOMETRY_LABEL:
      _.set(state, `series[${getSeriesIndex()}].shapefile.geometryLabel`, action.shapefileGeometryLabel);
      break;

    case actions.SET_MEASURE_AGGREGATION:
      _.set(state, `series[${getSeriesIndex()}].dataSource.measure.aggregationFunction`, action.aggregationFunction);
      break;

    case actions.SET_MEASURE_COLUMN:
      setMeasureColumnAtSeriesIndex(state, { columnName: action.columnName, seriesIndex: getSeriesIndex() });
      break;

    case actions.SET_BASEMAP_STYLE:
      _.set(state, 'configuration.basemapOptions.basemapStyle', action.basemapStyle);
      break;

    case actions.SET_BASEMAP_OPACITY:
      _.set(state, 'configuration.basemapOptions.basemapOpacity', action.basemapOpacity);
      break;

    case actions.SET_NAVIGATION_CONTROL:
      _.set(state, 'configuration.basemapOptions.navigationControl', action.navigationControl);
      break;

    case actions.SET_LAYER_TOGGLE_CONTROL:
      _.set(state, 'configuration.basemapOptions.layerToggleControl', action.layerToggleControl);
      break;

    case actions.SET_GEO_CODER_CONTROL:
      _.set(state, 'configuration.basemapOptions.geoCoderControl', action.geoCoderControl);
      break;

    case actions.SET_SHOW_DATA_TABLE_CONTROL:
      _.set(state, 'configuration.showDataTableControl', action.showDataTableControl);
      break;

    case actions.SET_GEO_LOCATE_CONTROL:
      _.set(state, 'configuration.basemapOptions.geoLocateControl', action.geoLocateControl);
      break;

    case actions.SET_MAP_FLYOUT_TITLE_COLUMN_NAME:
      _.set(state, `series[${getSeriesIndex()}].mapOptions.mapFlyoutTitleColumnName`, action.mapFlyoutTitleColumnName);
      break;

    case actions.ADD_BASEMAP_FLYOUT_COLUMN:
      const flyoutColumns = _.get(state, `series[${getSeriesIndex()}].mapOptions.additionalFlyoutColumns`, []);

      flyoutColumns.push(action.columnName);
      _.set(state, `series[${getSeriesIndex()}].mapOptions.additionalFlyoutColumns`, flyoutColumns);
      break;

    case actions.REMOVE_BASEMAP_FLYOUT_COLUMN:
      let additionalFlyoutColumns = _.get(state, `series[${getSeriesIndex()}].mapOptions.additionalFlyoutColumns`, []);

      additionalFlyoutColumns.splice(action.relativeIndex, 1);
      _.set(state, `series[${getSeriesIndex()}].mapOptions.additionalFlyoutColumns`, additionalFlyoutColumns);
      break;

    case actions.CHANGE_ADDITIONAL_FLYOUT_COLUMN:
      const columns = _.get(state, `series[${getSeriesIndex()}].mapOptions.additionalFlyoutColumns`, []);

      columns[action.relativeIndex] = action.columnName;
      _.set(state, `series[${getSeriesIndex()}].mapOptions.additionalFlyoutColumns`, columns);
      break;

    case actions.ADD_REGIONMAP_FLYOUT_COLUMN_AND_AGGREGATION:
      const columnAndAggregations = _.get(state, `series[${getSeriesIndex()}].mapOptions.regionMapFlyoutColumnAndAggregations`, []);
      const columnAndAggregation = _.isNil(action.columnAndAggregation.column) ?
        _.merge({}, action.columnAndAggregation, { aggregation: 'count' }) :
        action.columnAndAggregation;

      columnAndAggregations.push(columnAndAggregation);
      _.set(state, `series[${getSeriesIndex()}].mapOptions.regionMapFlyoutColumnAndAggregations`, columnAndAggregations);
      break;

    case actions.SET_REGIONMAP_FLYOUT_COLUMN_AND_AGGREGATION:
      const columnAndAggregationFunctions = action.columnAndAggregation;
      _.each(columnAndAggregationFunctions, (columnAndAggregationFunction) => {
        _.defaults(columnAndAggregationFunction, { aggregation: null });
      });

      _.set(state, `series[${getSeriesIndex()}].mapOptions.regionMapFlyoutColumnAndAggregations`, columnAndAggregationFunctions);
      break;

    case actions.REMOVE_REGIONMAP_FLYOUT_COLUMN_AND_AGGREGATION:
      let regionMapColumnAndAggregations = _.get(state, `series[${getSeriesIndex()}].mapOptions.regionMapFlyoutColumnAndAggregations`, []);

      regionMapColumnAndAggregations.splice(action.index, 1);
      _.set(state, `series[${getSeriesIndex()}].mapOptions.regionMapFlyoutColumnAndAggregations`, regionMapColumnAndAggregations);
      break;

    case actions.CHANGE_REGIONMAP_FLYOUT_COLUMN_AND_AGGREGATION:
      const regionMapFlyoutColumnAndAggregations = _.get(state, `series[${getSeriesIndex()}].mapOptions.regionMapFlyoutColumnAndAggregations`, []);
      let aggregation;

      // { column: null } => count of rows.
      // So we set the aggregation `count`.
      // {column: 'select_column'} => If a column is selected.
      // Default aggregation value is 'sum'.
      // So we set the aggregation `sum`.
      // Otherwise the selected aggregation value is set to aggregation.
      if (_.isNil(action.columnAndAggregation.column)) {
        aggregation = 'count';
      } else if (action.columnAndAggregation.aggregation === 'count') {
        aggregation = 'sum';
      } else {
        aggregation = action.columnAndAggregation.aggregation;
      }

      regionMapFlyoutColumnAndAggregations[action.index] = _.merge({}, action.columnAndAggregation, { aggregation });
      _.set(state, `series[${getSeriesIndex()}].mapOptions.regionMapFlyoutColumnAndAggregations`, regionMapFlyoutColumnAndAggregations);
      break;

    case actions.SET_ADDITIONAL_FLYOUT_COLUMNS:
      _.set(state, `series[${getSeriesIndex()}].mapOptions.additionalFlyoutColumns`, action.columns);
      break;

    case actions.SET_SEARCH_BOUNDARY_UPPER_LEFT_LATITUDE:
      const upperLeftLatitude = parseFloat(action.searchBoundaryUpperLeftLatitude);

      _.set(state, 'configuration.basemapOptions.searchBoundaryUpperLeftLatitude', upperLeftLatitude);
      break;

    case actions.SET_SEARCH_BOUNDARY_UPPER_LEFT_LONGITUDE:
      const upperLeftLongitude = parseFloat(action.searchBoundaryUpperLeftLongitude);

      _.set(state, 'configuration.basemapOptions.searchBoundaryUpperLeftLongitude', upperLeftLongitude);
      break;

    case actions.SET_SEARCH_BOUNDARY_LOWER_RIGHT_LATITUDE:
      const lowerRightLatitude = parseFloat(action.searchBoundaryLowerRightLatitude);

      _.set(state, 'configuration.basemapOptions.searchBoundaryLowerRightLatitude', lowerRightLatitude);
      break;

    case actions.SET_SEARCH_BOUNDARY_LOWER_RIGHT_LONGITUDE:
      const lowerRightLongitude = parseFloat(action.searchBoundaryLowerRightLongitude);

      _.set(state, 'configuration.basemapOptions.searchBoundaryLowerRightLongitude', lowerRightLongitude);
      break;

    case actions.SET_PITCH_AND_BEARING:
      _.set(state, 'configuration.mapPitchAndBearing', action.pitchAndBearing);
      break;

    case actions.SET_MAP_ZOOM_LEVEL:
      _.set(state, 'configuration.mapZoomLevel', action.mapZoomLevel);
      break;

    case actions.SET_SHAPE_FILL_COLOR:
      _.set(state, `series[${getSeriesIndex()}].mapOptions.shapeFillColor`, action.shapeFillColor);
      break;

    case actions.SET_CAST_NULL_AS_FALSE_IN_SERIES:
      _.set(state, `series[${getSeriesIndex()}].mapOptions.castNullAsFalseInSeries`, action.castNullAsFalseInSeries);
      break;

    case actions.SET_SHAPE_FILL_OPACITY:
      _.set(state, `series[${getSeriesIndex()}].mapOptions.shapeFillOpacity`, action.shapeFillOpacity);
      break;

    case actions.SET_SHAPE_OUTLINE_COLOR:
      _.set(state, `series[${getSeriesIndex()}].mapOptions.shapeOutlineColor`, action.shapeOutlineColor);
      break;

    case actions.SET_SHAPE_OUTLINE_WIDTH:
      _.set(state, `series[${getSeriesIndex()}].mapOptions.shapeOutlineWidth`, action.shapeOutlineWidth);
      break;

    case actions.SET_SHOW_LEGEND:
      _.set(state, `series[${getSeriesIndex()}].showLegend`, action.showLegend);
      break;

    case actions.SET_SHOW_LEGEND_FOR_MAP:
      _.set(state, 'configuration.showLegendForMap', action.showLegendForMap);
      break;

    case actions.SET_SHOW_LEGEND_OPENED:
      _.set(state, 'configuration.showLegendOpened', action.showLegendOpened);
      break;

    case actions.SWAP_COLOR_PALETTE:
      const currentCustomColorPalette = _.get(
        _.cloneDeep(state),
        ['series', getSeriesIndex(), 'color', 'customPalette', action.columnName]
      );

      _.each(currentCustomColorPalette, (palette) => {
        if (palette.index === action.toIndex) {
          palette.index = action.fromIndex;
        } else if (palette.index === action.fromIndex) {
          palette.index = action.toIndex;
        }
      });

      // In maps we need to update the custom color palette only for the selected map series.
      _.set(state, `series[${getSeriesIndex()}].color.customPalette.${action.columnName}`, currentCustomColorPalette);
      break;

    case actions.SWAP_COLOR_PALETTE_WITH_QUANTIFICATION: {
      const {
        columnName,
        fromIndex,
        toIndex,
        quantificationMethod
      } = action;

      const currentCustomColorPalette = _.get(
        _.cloneDeep(state),
        ['series', getSeriesIndex(), 'color', 'customPalette', columnName, quantificationMethod]
      );

      _.each(currentCustomColorPalette, (palette) => {
        if (palette.index === toIndex) {
          palette.index = fromIndex;
        } else if (palette.index === fromIndex) {
          palette.index = toIndex;
        }
      });

      // In maps we need to update the custom color palette only for the selected map series.
      _.set(state, `series[${getSeriesIndex()}].color.customPalette.${action.columnName}.${quantificationMethod}`, currentCustomColorPalette);
      break;
    }

    case actions.SWAP_SERIES:
      const series = _.get(state, 'series', []);

      [series[action.fromIndex], series[action.toIndex]] = [series[action.toIndex], series[action.fromIndex]];
      _.set(state, 'series', series);
      break;

    case actions.APPEND_SERIES:
      if (action.isMapSeries) {
        appendSeries(state, action);
      }

      break;

    case actions.SET_FILTERS:
      _.set(state, `series[${getSeriesIndex()}].dataSource.filters`, action.filters);
      break;

    case actions.SET_LAYER_AS_PRIMARY:
      _.set(state, `series[${action.relativeIndex}].primary`, action.isPrimary);
      break;

    case actions.SET_MAP_LAYER_VISIBLE:
      _.set(state, `series[${action.relativeIndex}].visible`, action.isVisible);
      break;

    case actions.SET_SIMPLIFICATION_LEVEL:
      _.set(state, `series[${action.relativeIndex}].mapOptions.simplificationLevel`, action.simplificationLevel);
      break;

    case actions.SET_CHARM_NAME:
      _.set(state, `series[${action.relativeIndex}].mapOptions.charmName`, action.charmName);
      break;

    case actions.REMOVE_SERIES:
    case actions.RECEIVE_METADATA:
    case actions.SET_TITLE:
    case actions.SET_DESCRIPTION:
    case actions.SET_MAP_LEGEND_PRECISION:
    case actions.SET_MAP_FLYOUT_PRECISION:
    case actions.SET_UNIT_ONE:
    case actions.SET_UNIT_OTHER:
    case actions.SET_VIEW_SOURCE_DATA_LINK:
      return baseVifReducer(state, action);

    default:
      break;
  }

  return state;
}

function setDefault({ value: defaultValue, constantKey: constantKeyToFetchValue }) {
  const minValue = VIF_CONSTANTS[constantKeyToFetchValue].MIN;
  const maxValue = VIF_CONSTANTS[constantKeyToFetchValue].MAX;

  return _.isFinite(defaultValue) ? _.clamp(defaultValue, minValue, maxValue) : null;
}
