// Vendor Imports
import _ from 'lodash';

// Project Imports
import baseVifReducer from './base';
import {
  appendSeries,
  setBooleanValueOrDefaultValue,
  setDimensionGroupingColumnName,
  tryUnsetShowLegend
} from '../../helpers';

import * as actions from '../../actions';

// Constants
import vifs from '../../vifs';

export default function timelineChart(state, action) {
  if (_.isUndefined(state)) {
    return vifs().timelineChart;
  }

  state = _.cloneDeep(state);

  switch (action.type) {

    case actions.RESET_STATE:
      state = vifs().timelineChart;
      break;

    case actions.REPLACE_VIF:
      const chartType = _.get(action, 'vif.series[0].type');
      state = chartType === 'timelineChart' ? { ...state, ...action.vif } : state;
      break;

    case actions.SET_SHOW_DIMENSION_LABELS:
      setBooleanValueOrDefaultValue(state, 'configuration.showDimensionLabels', action.showDimensionLabels, true);
      break;

    case actions.SET_DIMENSION_LABEL_AREA_SIZE:
      _.set(state, 'configuration.dimensionLabelAreaSize', action.width);
      break;

    case actions.SET_SHOW_ANNOTATIONS_IN_LEGEND:
      _.set(state, 'configuration.showAnnotationsInLegend', action.showAnnotationsInLegend);
      break;

    case actions.SET_DIMENSION_GROUPING_COLUMN_NAME:
      setDimensionGroupingColumnName(state, action.dimensionGroupingColumnName);
      break;

    case actions.SET_CUSTOM_COLOR_PALETTE:
      const customColorPalette = action.customColorPalette;
      const grouping = action.dimensionGroupingColumnName;
      _.set(state, 'series[0].color.customPalette', {
        [grouping]: customColorPalette
      });
      break;

    case actions.UPDATE_CUSTOM_COLOR_PALETTE: {
      const { dimensionGroupingColumnName, group, selectedColor } = action;
      const path = ['series', 0, 'color', 'customPalette', dimensionGroupingColumnName, group, 'color'];
      _.set(state, path, selectedColor);
      break;
    }

    case actions.SET_ANNOTATIONS:
      setAnnotations(state, action.annotations);
      break;

    case actions.ADD_ANNOTATION:
      const annotations = _.get(state, 'series[0].annotations', []);

      annotations.push(action.annotation);

      setAnnotations(state, annotations);
      break;

    case actions.UPDATE_ANNOTATION:
      const updateAnnotations = _.get(state, 'series[0].annotations', []);

      updateAnnotations[action.index] = action.annotation;

      setAnnotations(state, updateAnnotations);
      break;

    case actions.REMOVE_ANNOTATION:
      const newAnnotations = _.get(state, 'series[0].annotations', []);

      newAnnotations.splice(action.index, 1);

      setAnnotations(state, newAnnotations);

      if (_.isEmpty(newAnnotations)) {
        tryUnsetShowLegend(state);
      }
      break;

    case actions.SET_SHOW_LINE_VALUE_LABELS:
      // Set showValueLabels since for timeline there is just the one kind of value label as
      // opposed to combo chart where there are column value labels and line value labels.
      setBooleanValueOrDefaultValue(state, 'configuration.showValueLabels', action.showValueLabels, true);
      break;

    case actions.APPEND_SERIES:
      appendSeries(state, action);
      break;

    case actions.APPEND_REFERENCE_LINE:
    case actions.RECEIVE_METADATA:
    case actions.REMOVE_REFERENCE_LINE:
    case actions.REMOVE_SERIES:
    case actions.SET_COLOR_PALETTE:
    case actions.SET_DATASET_UID:
    case actions.SET_DATE_DISPLAY_FORMAT:
    case actions.SET_DESCRIPTION:
    case actions.SET_DIMENSION:
    case actions.SET_DIMENSION_LABEL_ROTATION_ANGLE:
    case actions.SET_DOMAIN:
    case actions.SET_FILTERS:
    case actions.SET_GROUPING_ORDER_BY:
    case actions.SET_LABEL_BOTTOM:
    case actions.SET_LABEL_LEFT:
    case actions.SET_LEGEND_POSITION:
    case actions.SET_LINE_STYLE_POINT_RADIUS:
    case actions.SET_LINE_STYLE_POINTS:
    case actions.SET_LOGARITHMIC_SCALE:
    case actions.SET_MEASURE_AGGREGATION:
    case actions.SET_MEASURE_AXIS_MAX_VALUE:
    case actions.SET_MEASURE_AXIS_MIN_VALUE:
    case actions.SET_MEASURE_AXIS_PRECISION:
    case actions.SET_MEASURE_AXIS_SCALE:
    case actions.SET_MEASURE_COLUMN:
    case actions.SET_ORDER_BY:
    case actions.SET_PRECISION:
    case actions.SET_PRIMARY_COLOR:
    case actions.SET_REFERENCE_LINE_COLOR:
    case actions.SET_REFERENCE_LINE_LABEL:
    case actions.SET_REFERENCE_LINE_VALUE:
    case actions.SET_SHOW_DATA_TABLE_CONTROL:
    case actions.SET_SHOW_LEGEND:
    case actions.SET_SHOW_LEGEND_OPENED:
    case actions.SET_SHOW_NULLS_AS_FALSE:
    case actions.SET_SHOW_VALUE_LABELS:
    case actions.SET_TITLE:
    case actions.SET_TREAT_NULL_VALUES_AS_ZERO:
    case actions.SET_UNIT_ONE:
    case actions.SET_UNIT_OTHER:
    case actions.SET_VIEW_SOURCE_DATA_LINK:
    case actions.SET_WRAP_DIMENSION_LABELS:
    case actions.SET_X_AXIS_SCALING_MODE:
    case actions.SWAP_COLOR_PALETTE:
      return baseVifReducer(state, action);

    default:
      break;
  }

  return state;
}

function setAnnotations(state, annotations) {
  _.each(state.series, series => {
    _.set(series, 'annotations', annotations);
  });
}
