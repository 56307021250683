import _ from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { replaceVif, setVisualizationType } from '../../../actions';
import { generateVif } from 'common/authoring_workflow/helpers/aiVifHelper';

const AiChatBot = () => {
  const dispatch = useDispatch();

  const onUpdateVif = (vif) => {
    const chartType = _.get(vif, 'series[0].type', 'barChart');
    dispatch(setVisualizationType(chartType));
    dispatch(replaceVif(vif));
  };

  const generateVifFunction = ({ vif, vifActionType }) => {
    const newVif = vifActionType == 'update' ? vif : generateVif(vif);
    onUpdateVif(newVif);
  };

  useEffect(() => {
    window.generateVifFunction = generateVifFunction;
  }, []);

  return <div id="ai-chatbot-container"></div>;
};

export default AiChatBot;
