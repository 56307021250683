// Vendor Imports
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Project Imports
import * as selectors from '../../../selectors/vifAuthoring';
import AxisTitlesSelector from './AxisTitlesSelector';
import ColorPaletteSelector from './ColorPaletteSelector';
import ColorSelector from './ColorSelector';
import CalendarColorSelector from './CalendarColorSelector';
import FeatureMapSelector from './FeatureMapSelector';
import GroupingsSelector from './GroupingsSelector';
import LabelsSelector from './LabelsSelector';
import MapLayerSelector from './MapLayerSelector';
import RegionMapSelector from './RegionMapSelector';
import ScatterChartPlotSizeSelector from './ScatterChartPlotSizeSelector';
import ScatterChartColorPaletteSelector from './ScatterChartColorPaletteSelector';
import ShowDataTableControl from '../../shared/ShowDataTableControl';
import LockCalendarViewControl from './LockCalendarViewControl';
import TitleDescriptionSelector from './TitleDescriptionSelector';
import EmptyPane from '../EmptyPane';
import AgGridUpdatedColumnFormatting from './AgGridUpdated/AgGridColumnFormatting';
import AgGridHeaderFormatSelector from './AgGridHeader/AgGridHeaderFormatSelector';
import {
  ForgeAccordionContainer as AccordionContainer,
  ForgeAccordionPane as AccordionPane
} from 'common/components/Accordion';
import I18n from 'common/i18n';
import { getCurrentMetadata, hasAtleastOneBooleanColumn, isBooleanColumn } from '../../../selectors/metadata';

// Constants
import { SERIES_TYPE_FLYOUT } from '../../../constants';
import RowStripeSelector from './RowStripeSelector';

const scope = 'shared.visualizations.panes.presentation';
const commonScope = 'common.ag_grid_react';

export class PresentationPane extends Component {
  renderPrimaryColor = () => {
    return (
      <AccordionPane key="colors" title={I18n.t('subheaders.colors', { scope })}>
        <ColorSelector />
      </AccordionPane>
    );
  };

  renderColorPalette = () => {
    return (
      <AccordionPane key="colors" title={I18n.t('subheaders.colors', { scope })}>
        <ColorPaletteSelector />
      </AccordionPane>
    );
  };

  renderLabels = () => {
    return (
      <AccordionPane key="labels" title={I18n.t('subheaders.labels', { scope })}>
        <LabelsSelector />
      </AccordionPane>
    );
  };

  renderComboChartVisualizationLabels = () => {
    const { vifAuthoring } = this.props;
    const usePrimaryAxis =
      !selectors.getUseSecondaryAxisForColumns(vifAuthoring) ||
      !selectors.getUseSecondaryAxisForLines(vifAuthoring);
    const useSecondaryAxis =
      selectors.getUseSecondaryAxisForColumns(vifAuthoring) ||
      selectors.getUseSecondaryAxisForLines(vifAuthoring);

    return this.renderAxisTitles({
      bottom: true,
      left: usePrimaryAxis,
      right: useSecondaryAxis
    });
  };

  renderPlotSizeSelector = () => {
    return (
      <AccordionPane key="plotSizeControls" title={I18n.t('subheaders.point_size', { scope })}>
        <ScatterChartPlotSizeSelector />
      </AccordionPane>
    );
  };

  renderAxisTitles = ({ bottom = false, left = false, right = false, top = false }) => {
    const selectorProps = { bottom, left, right, top };

    return (
      <AccordionPane key="axis-labels" title={I18n.t('subheaders.axis_titles', { scope })}>
        <AxisTitlesSelector {...selectorProps} />
      </AccordionPane>
    );
  };

  renderGeneral = () => {
    const { vifAuthoring } = this.props;
    const isCalendar = selectors.isCalendar(vifAuthoring);
    const isNotTable = !selectors.isTable(vifAuthoring);
    return (
      <AccordionPane title={I18n.t('subheaders.general', { scope })}>
        <TitleDescriptionSelector />
        {isNotTable && <ShowDataTableControl />}
        {isCalendar && <LockCalendarViewControl />}
      </AccordionPane>
    );
  };

  renderGroupedBarChartControls = () => {
    return [this.renderColorPalette(), this.renderLabels(), this.renderAxisTitles({ left: true, top: true })];
  };

  renderBarChartControls = () => {
    return [this.renderPrimaryColor(), this.renderLabels(), this.renderAxisTitles({ left: true, top: true })];
  };

  renderGroupedColumnChartControls = () => {
    return [
      this.renderColorPalette(),
      this.renderLabels(),
      this.renderAxisTitles({ bottom: true, left: true })
    ];
  };

  renderColumnChartControls = () => {
    return [
      this.renderPrimaryColor(),
      this.renderLabels(),
      this.renderAxisTitles({ bottom: true, left: true })
    ];
  };

  renderGroupedComboChartControls = () => {
    return [this.renderColorPalette(), this.renderLabels(), this.renderComboChartVisualizationLabels()];
  };

  renderComboChartControls = () => {
    return [this.renderPrimaryColor(), this.renderLabels(), this.renderComboChartVisualizationLabels()];
  };

  renderScatterChartColorContorls = () => {
    const { vifAuthoring } = this.props;
    const colorSelector = selectors.isColorByColumnConfigured(vifAuthoring) ? (
      <ScatterChartColorPaletteSelector />
    ) : (
      <ColorSelector />
    );

    return (
      <AccordionPane key="colors" title={I18n.t('subheaders.colors', { scope })}>
        {colorSelector}
      </AccordionPane>
    );
  };

  renderScatterChartLabels = () => {
    const { metadata, vifAuthoring } = this.props;
    const colorByColumn = selectors.getColorByColumn(vifAuthoring);
    const xAxisColumn = selectors.getXAxisColumn(vifAuthoring);
    const series = selectors.getSeries(vifAuthoring).map((item, index) => {
      return _.extend({ seriesIndex: index }, item);
    });
    const flyoutSeries = _.filter(series, (item) => {
      return item.type === SERIES_TYPE_FLYOUT;
    });
    const additionalFlyoutColumns = _.map(flyoutSeries, (seriesItem) =>
      _.get(seriesItem, 'dataSource.measure.columnName')
    );
    const isColorByBooleanColumn = isBooleanColumn(metadata, colorByColumn);
    const isXAxisBooleanColumn = isBooleanColumn(metadata, xAxisColumn);
    const hasAtleastOneFlyoutBooleanColumn = hasAtleastOneBooleanColumn(metadata, additionalFlyoutColumns);

    return isXAxisBooleanColumn || isColorByBooleanColumn || hasAtleastOneFlyoutBooleanColumn
      ? this.renderLabels()
      : null;
  };

  renderScatterChartControls = () => {
    return [
      this.renderScatterChartColorContorls(),
      this.renderScatterChartLabels(),
      this.renderPlotSizeSelector(),
      this.renderAxisTitles({
        bottom: true,
        top: true,
        left: true,
        right: true
      })
    ];
  };

  renderCalendarControls = () => {
    return (
      <AccordionPane key="colors" title={I18n.t('subheaders.colors', { scope })}>
        <CalendarColorSelector />
      </AccordionPane>
    );
  };

  renderHistogramControls = () => {
    return [this.renderPrimaryColor(), this.renderAxisTitles({ bottom: true, left: true })];
  };

  renderTimelineChartControls = () => {
    return [
      this.renderPrimaryColor(),
      this.renderLabels(),
      this.renderAxisTitles({ bottom: true, left: true })
    ];
  };

  renderGroupedTimelineChartControls = () => {
    return [
      this.renderColorPalette(),
      this.renderLabels(),
      this.renderAxisTitles({ bottom: true, left: true })
    ];
  };

  renderFeatureMapControls = () => {
    const pointControls = (
      <AccordionPane key="pointControls" title={I18n.t('subheaders.points', { scope })}>
        <FeatureMapSelector />
      </AccordionPane>
    );

    return [pointControls, this.renderMapLayerControls()];
  };

  renderRegionMapControls = () => {
    const colorControls = (
      <AccordionPane key="colorControls" title={I18n.t('subheaders.colors', { scope })}>
        <RegionMapSelector />
      </AccordionPane>
    );

    return [colorControls, this.renderMapLayerControls()];
  };

  renderMapLayerControls = () => {
    return (
      <AccordionPane key="mapLayerControls" title={I18n.t('subheaders.map', { scope })}>
        <MapLayerSelector />
      </AccordionPane>
    );
  };

  renderPieChartControls = () => {
    return [this.renderColorPalette(), this.renderLabels()];
  };
  renderAgColumnFormatSelector = () => {
    const accordionPaneTitle = I18n.t('columns', { scope: commonScope });
    return (
      <AccordionPane key="agGridTableColumnControls" title={accordionPaneTitle}>
        <AgGridUpdatedColumnFormatting />
      </AccordionPane>
    );
  };

  renderAgHeaderFormatter = () => {
    return (
      <AccordionPane key="agGridTableHeaderControls" title="Headers">
        <AgGridHeaderFormatSelector />
      </AccordionPane>
    );
  };

  renderEmptyPane = () => {
    return <EmptyPane />;
  };

  renderGroupings = () => {
    return (
      <AccordionPane key="tableGroupings" title={I18n.t('subheaders.groupings', { scope })}>
        <GroupingsSelector />
      </AccordionPane>
    );
  };

  renderRowStripeControls = () => {
    return (
      <AccordionPane key={'rowStripeControls'} title={I18n.t('subheaders.row_stripe_title', { scope })}>
        <RowStripeSelector />
      </AccordionPane>
    );
  };

  render() {
    let configuration = null;
    let showLabels = null;
    let vifAuthoring = this.props.vifAuthoring;
    let general = this.renderGeneral();

    if (selectors.isBarChart(vifAuthoring)) {
      if (selectors.isGroupingOrHasMultipleNonFlyoutSeries(vifAuthoring)) {
        configuration = this.renderGroupedBarChartControls();
      } else {
        configuration = this.renderBarChartControls();
      }
    } else if (selectors.isCalendar(vifAuthoring)) {
      configuration = this.renderCalendarControls();
    } else if (selectors.isColumnChart(vifAuthoring)) {
      if (selectors.isGroupingOrHasMultipleNonFlyoutSeries(vifAuthoring)) {
        configuration = this.renderGroupedColumnChartControls();
      } else {
        configuration = this.renderColumnChartControls();
      }
    } else if (selectors.isComboChart(vifAuthoring)) {
      if (selectors.isGroupingOrHasMultipleNonFlyoutSeries(vifAuthoring)) {
        configuration = this.renderGroupedComboChartControls();
      } else {
        configuration = this.renderComboChartControls();
      }
    } else if (selectors.isScatterChart(vifAuthoring)) {
      configuration = this.renderScatterChartControls();
    } else if (selectors.isTimelineChart(vifAuthoring)) {
      if (selectors.isGroupingOrHasMultipleNonFlyoutSeries(vifAuthoring)) {
        configuration = this.renderGroupedTimelineChartControls();
      } else {
        configuration = this.renderTimelineChartControls();
      }
    } else if (selectors.isHistogram(vifAuthoring)) {
      configuration = this.renderHistogramControls();
    } else if (selectors.isFeatureMap(vifAuthoring)) {
      configuration = this.renderFeatureMapControls();
    } else if (selectors.isRegionMap(vifAuthoring)) {
      configuration = this.renderRegionMapControls();
    } else if (selectors.isPieChart(vifAuthoring)) {
      configuration = this.renderPieChartControls();
    } else if (selectors.isTable(vifAuthoring)) {
      configuration = [this.renderAgHeaderFormatter()];

      configuration.push(this.renderAgColumnFormatSelector());
      configuration.push(this.renderRowStripeControls());

      if (configuration == null) {
        configuration = [this.renderGroupings()];
      } else {
        configuration.push(this.renderGroupings());
      }
    } else {
      configuration = this.renderEmptyPane();
      general = null;
    }

    return (
      <form>
        <AccordionContainer>
          {general}
          {configuration}
          {showLabels}
        </AccordionContainer>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  metadata: getCurrentMetadata(state.metadataCollection, state.vifAuthoring),
  vifAuthoring: state.vifAuthoring
});

export default connect(mapStateToProps)(PresentationPane);
