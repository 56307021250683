import React, { Component } from 'react';
import { ForgeSwitch } from '@tylertech/forge-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setTableIndentedLayout } from '../../../actions';
import I18n from 'common/i18n';

export class GroupingsSelector extends Component {
  constructor(props) {
    super(props);

    this.props.onSetTableIndentedLayout(this.defaultToggleValue());
  }

  toggleIndentedLayout = (event) => {
    this.props.onSetTableIndentedLayout(event.detail);
  };

  defaultToggleValue = () => {
    const { configuration } = this.props;

    if (configuration) {
      //might want to review the logic here because Object.keys length cannot be less than 0.
      return configuration.isIndented || Object.keys(configuration).length < 0;
    }

    return true;
  };

  render() {
    return (
      <ForgeSwitch on-forge-switch-select={this.toggleIndentedLayout} selected={this.defaultToggleValue()}>
        <span id="indented-layout-label">
          {I18n.t('shared.visualizations.panes.presentation.fields.indented_layout')}
        </span>
      </ForgeSwitch>
    );
  }
}

GroupingsSelector.propTypes = {
  vifAuthoring: PropTypes.object
};

const mapDispatchToProps = {
  onSetTableIndentedLayout: setTableIndentedLayout
};

const mapStateToProps = (state) => ({
  configuration: state.vifAuthoring.vifs.initialVif.configuration
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupingsSelector);
